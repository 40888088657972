import React, { useState, useEffect } from 'react'
import {
	CardContent,
	CardActionArea,
	Card,
	Box,
	Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid2'

function Patient() {
	const [patients, setPatients] = useState([])
	const navigate = useNavigate()

	useEffect(() => {
		const fetchPatients = async () => {
			try {
				const response = await fetch('https://api.benderymed.ru/api/patients')
				const data = await response.json()
				setPatients(data)
			} catch (error) {
				console.error('Ошибка при загрузке пациентов', error)
			}
		}
		fetchPatients()
	}, [])

	const handlePatientClick = index => {
		if (index === 2) {
			navigate('/dashboard', {
				state: { selectedPatient: patients[index] },
			})
		} else {
			alert('Данный пациент не доступен. Выберите другого пациента!')
		}
	}

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				justifyContent: 'center',
				padding: '100px 0 0 0',
				overflow: 'hidden',
			}}
		>
			<Typography
				variant='h3'
				color='white'
				sx={{ fontSize: '31px', fontWeight: '700', paddingBottom: '35px' }}
			>
				Выберите пациента:
			</Typography>

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 2,
					flexWrap: 'wrap',
					maxWidth: '100%',
					justifyContent: 'space-between',
				}}
			>
				{patients.map((patient, index) => (
					<Grid size={12}>
						<Card
							sx={{
								background: 'none',
								backgroundColor: '#D9D9D9',
								border: '3px solid transparent',
								borderRadius: '5px',
								transition: 'all 0.3s ease',
								'&:hover': {
									border: '3px solid #FFFFFF',
									background: 'none',
									'& .card-text': {
										color: '#fff',
									},
								},
							}}
						>
							<CardActionArea
								sx={{ height: '100px', width: '100%' }}
								onClick={() => handlePatientClick(index)}
							>
								<CardContent
									sx={{
										padding: '0',
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
									}}
								>
									<img
										alt='Logo'
										src={`/images/patients/patient${index + 1}.svg`}
										style={{
											width: '100px',
											height: '100px',
											marginLeft: '22px',
										}}
									/>
									<Typography
										className='card-text'
										variant='body2'
										sx={{
											color: 'black',
											fontWeight: '400',
											fontSize: '24px',
											paddingLeft: '54px',
											paddingRight: '64px',
											transition: 'color 0.3s ease',
										}}
									>
										{patient.diagnosis}
									</Typography>
								</CardContent>
							</CardActionArea>
						</Card>
					</Grid>
				))}
			</Box>
		</Box>
	)
}

export default Patient
