import React from 'react'
import Grid from '@mui/material/Grid2'
import { Avatar, Typography } from '@mui/material'
import AppsIcon from '@mui/icons-material/Apps'

function Header() {
	return (
		<Grid
			container
			spacing={2}
			sx={{
				display: 'flex',
				flexDirection: 'row',
				height: '80px',
				alignItems: 'center',
				justifyContent: 'space-between',
			}}
		>
			<Grid
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					gap: 2,
				}}
			>
				<Avatar
					alt='Logo'
					src='/images/logo.webp'
					sx={{ width: '80px', height: '80px' }}
				/>
				<Typography
					variant='h3'
					color='white'
					sx={{
						display: { xs: 'none', sm: 'block' },
						fontSize: { sm: '27px', md: '40px' },
						fontWeight: '700',
					}}
				>
					Симуляционная лаборатория
				</Typography>
			</Grid>
			<Grid
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					gap: '2',
				}}
			>
				<Avatar
					alt='Logo'
					src='/images/img.png'
					sx={{ width: '80px', height: '80px', backgroundColor: '#fff' }}
				/>
				<AppsIcon sx={{ fontSize: '80px', color: '#fff', right: '0' }} />
			</Grid>
		</Grid>
	)
}

export default Header
