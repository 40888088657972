import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from './Components/Login/Login'
import Dashboard from './Components/Dashboard/Dashboard'
import Patient from './Components/Patient/Patient'
import Scenario from './Components/Scenario/Scenario'
import { Box, Container } from '@mui/material'
import Header from './Components/Header/Header'

function App() {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				minHeight: '100vh',
				p: '0',
				backgroundColor: '#68A5B8',
				overflow: 'hidden',
				paddingTop: '50px',
				flexGrow: 1,
			}}
		>
			<Container sx={{ maxWidth: 'lg', flexGrow: 1 }}>
				<Header />
				<Routes>
					<Route path='/' element={<Login />} />
					<Route path='/dashboard' element={<Dashboard />} />
					<Route path='/patient' element={<Patient />} />
					<Route path='/scenario' element={<Scenario />} />
				</Routes>
			</Container>
		</Box>
	)
}

export default App
