import React from 'react'
import ReactDOM from 'react-dom/client'
import './css/reset.css'
import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

const theme = createTheme({
	typography: {
		fontFamily: '"Montserrat", "Arial", sans-serif', // Укажите желаемый шрифт
	},
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<ThemeProvider theme={theme}>
		<CssBaseline />
		<BrowserRouter
			future={{ v7_startTransition: true, v7_relativeSplatPath: true }}
		>
			<App />
		</BrowserRouter>
	</ThemeProvider>
)
