import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
	Box,
	Typography,
	Autocomplete,
	TextField,
	Button,
	Card,
	CardActionArea,
	CardContent,
} from '@mui/material'
import Grid from '@mui/material/Grid2'

function Scenario() {
	const navigate = useNavigate()
	const handleCheckScenario = () => {
		navigate('/patient')
	}
	const simulations = [
		{
			label: 'Сбор жалоб',
		},
		{ label: 'Анамнез жизни' },
		{ label: 'Аллергологический анамнез' },
		{ label: 'Сестринский уход' },
		{ label: 'Анамнез заболевания' },
	]
	const scenario = [
		{ label: 'Ваши последние тесты' },
		{ label: 'Материалы для изучения' },
		{ label: 'Пройденные формы контроля' },
	]
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', padding: '0' }}>
			<Typography
				variant='h4'
				color='white'
				sx={{
					fontWeight: '500',
					fontSize: '31px',
					lineHeight: '37px',
					paddingTop: '85px',
					maxWidth: '768px',
				}}
			>
				На платформе лаборатории доступны разные методы диагностики состояния
				пациентов, основанные на технологиях “искуственного интеллекта”.{' '}
			</Typography>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Typography
					variant='h4'
					color='white'
					sx={{ fontWeight: '700', fontSize: '31px', lineHeight: '37px' }}
				>
					Выберите нужную симуляцию:
				</Typography>
				<Autocomplete
					disablePortal
					options={simulations}
					sx={{ width: '100%' }}
					renderInput={params => (
						<TextField
							sx={{
								backgroundColor: '#D9D9D9',
								borderRadius: '5px',
								'& .MuiInputBase-input': {
									fontSize: '35px', // Увеличиваем шрифт текста в поле
									color: '#1E1E1E', // Цвет текста
								},
								'& .MuiInputLabel-root': {
									fontSize: '31px',
									top: '-10px',
									padding: '0',
									color: '#97A7A7',
									'&.MuiInputLabel-shrink': {
										top: '0', // Корректируем положение при сжатии
									},
								},
							}}
							{...params}
							label='Выберите или введите название симуляции...'
						/>
					)}
					componentsProps={{
						popper: {
							sx: {
								'& .MuiAutocomplete-listbox': {
									'& .MuiAutocomplete-option': {
										fontSize: '31px',
										color: '#1E1E1E',
										padding: '10px 20px',
										'&:hover': {
											color: '#68A5B8', // Цвет текста при наведении
										},
									},
								},
							},
						},
					}}
				/>
				<Button
					type='submit'
					fullWidth
					variant='contained'
					color='primary'
					onClick={handleCheckScenario}
					sx={{
						mt: 2,
						padding: '11px 40px',
						fontWeight: '400',
						fontSize: '24px',
						maxWidth: { xs: '100%', sm: '100%', md: '416px' },
						backgroundColor: '#D9D9D9',
						color: '#000',
						'&:hover': {
							backgroundColor: '#0493ac',
						},
					}}
				>
					Подтвердить выбор и продолжить
				</Button>
			</Box>
			<Grid container spacing={2} sx={{ paddingTop: '95px' }}>
				{scenario.map((sc, index) => (
					<Grid size={3}>
						<Card
							sx={{
								background: 'none',
								border: '5px solid #FFFFFF',
								borderRadius: '5px',
							}}
						>
							<CardActionArea sx={{ height: '180px' }}>
								<CardContent
									sx={{
										padding: '18',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'space-between',
										alignItems: 'flex-end',
									}}
								>
									<img
										alt='Logo'
										src={`/images/icons/icon${index + 1}.svg`}
										sx={{ width: '47px', height: '47px' }}
									/>
									<Typography
										variant='body2'
										sx={{
											color: 'white',
											marginLeft: '39px',
											marginBottom: '20px',
											fontWeight: '500',
											fontStyle: 'italic',
											fontSize: '24px',
											marginRight: 'auto',
										}}
									>
										{sc.label}
									</Typography>
								</CardContent>
							</CardActionArea>
						</Card>
					</Grid>
				))}
			</Grid>
		</Box>
	)
}

export default Scenario
