import React from 'react'
import { Box, TextField, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

function Login() {
	const navigate = useNavigate()
	const handleLogin = () => {
		navigate('/scenario')
	}
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				paddingTop: { xs: 5, sm: 10, md: 15 },
			}}
		>
			<TextField
				sx={{
					maxWidth: { xs: '100%', sm: '100%', md: '50%' },
					backgroundColor: '#ffffff',
					borderRadius: '4px',
					'& .MuiOutlinedInput-root': {
						'& fieldset': {
							borderColor: '#ffffff', // Цвет рамки
						},
						'&:hover fieldset': {
							borderColor: '#000', // Цвет рамки при наведении
						},
						'&.Mui-focused fieldset': {
							borderColor: '#000', // Цвет рамки при фокусе
						},
					},
					'& .MuiInputLabel-root': {
						color: '#000', // Цвет текста лейбла
					},
				}}
				label='Логин'
				type='username'
				fullWidth
				margin='normal'
				required
			/>

			<TextField
				sx={{
					maxWidth: { xs: '100%', sm: '100%', md: '50%' },
					backgroundColor: '#ffffff',
					borderRadius: '4px',
					'& .MuiOutlinedInput-root': {
						'& fieldset': {
							borderColor: '#ffffff', // Цвет рамки
						},
						'&:hover fieldset': {
							borderColor: '#000', // Цвет рамки при наведении
						},
						'&.Mui-focused fieldset': {
							borderColor: '#000', // Цвет рамки при фокусе
						},
					},
					'& .MuiInputLabel-root': {
						color: '#000', // Цвет текста лейбла
					},
				}}
				label='Пароль'
				type='password'
				fullWidth
				margin='normal'
				required
			/>

			<Button
				type='submit'
				fullWidth
				variant='contained'
				color='primary'
				onClick={handleLogin}
				sx={{
					mt: 2,
					maxWidth: { xs: '100%', sm: '100%', md: '50%' },
					backgroundColor: '#005f73',
					'&:hover': {
						backgroundColor: '#004c59',
					},
				}}
			>
				Войти
			</Button>
		</Box>
	)
}

export default Login
